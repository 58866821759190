import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {
  Section,
  Container,
  SectionDescription,
  ExploreMore,
  BreadCrumb,
} from "../components/Section";
import {
  HeroBanner,
  HeroTitle,
  HeroCaption,
  HeroContent,
  HeroLeft,
  HeroRight,
  HeroCard,
} from "../components/HeroBanner";
import JoinForm from "../components/JoinForm";
import ProductCard from "../components/ProductCard";
import PrimaryButton from "../components/Button/PrimaryButton";
import PhotoModal from "../components/PhotoModal";

const ProductGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -15px;
  @media (min-width: 992px) {
    margin: 100px -15px 0 -15px;
  }
  @media (min-width: 1200px) {
    margin: 120px -30px 0 -30px;
  }
`;
const ProductGridItem = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  @media (min-width: 992px) {
    max-width: 50%;
    flex: 0 0 50%;
  }
  @media (min-width: 1200px) {
    padding: 0 30px;
  }

  &:nth-child(odd) {
    @media (min-width: 992px) {
      margin-top: -120px;
    }
  }

  .card {
    padding: 10px 10px 20px;
    border: 1px solid #f5f5f5;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);

    margin-bottom: 40px;
    @media (min-width: 1200px) {
      margin-bottom: 80px;
    }
  }
`;

const GalleryPage = ({ data, location }) => {
  const pageData = data.contentfulPageGallery;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showLimit, setShowLimit] = useState(6);
  const allProducts = data.allContentfulProduct.edges;
  const [openProduct, setOpenProduct] = useState(allProducts[0]);

  const HandleModalOpen = (sku) => {
    setIsModalVisible(true);
    const product = allProducts.filter((item) => item.node.skuNumber === sku);
    console.log(product[0]);
    setOpenProduct(product[0]);
  };
  const HandleModalClose = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    if (isModalVisible) {
      document.querySelector("body").classList.add("modal-open");
    } else {
      document.querySelector("body").classList.remove("modal-open");
    }
  }, [isModalVisible, setIsModalVisible]);
  return (
    <Layout location={location}>
      <Seo
        title={pageData.metaTitle}
        description={pageData.metaDescription}
        location={location}
      />
      <Section
        bgColor="#F8FAFB"
        bgBefore="#002765"
        bgBeforeHeight="80%"
        bgBeforeWidth="100%"
      >
        <HeroBanner
          className="banner-hero"
          bgBefore="block"
          data-shadowtext={pageData.heroTitle}
        >
          <HeroCaption>
            <Container maxWidth="1640px">
              <BreadCrumb>
                <Link to="/">Home</Link>
                <span>Gallery</span>
              </BreadCrumb>
              <HeroContent>
                <HeroLeft>
                  <HeroTitle>{pageData.heroTitle}</HeroTitle>
                </HeroLeft>
                <HeroRight>
                  <HeroCard>
                    <JoinForm location={location} />
                  </HeroCard>
                </HeroRight>
              </HeroContent>
            </Container>
          </HeroCaption>
        </HeroBanner>
      </Section>

      <Section
        ept="120px"
        epb="120px"
        xpt="120px"
        xpb="80px"
        pt="40px"
        pb="40px"
        bgColor="#F8FAFB"
        lmt="-80px"
        className="section-siteprep"
      >
        <Container>
          <SectionDescription>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  pageData.gallerySectionDescription.childMarkdownRemark.html,
              }}
            />
          </SectionDescription>
          <ProductGrid>
            {allProducts.map((item, i) => {
              if (i < showLimit) {
                return (
                  <ProductGridItem
                    key={i}
                    onClick={() => HandleModalOpen(item.node.skuNumber)}
                  >
                    <ProductCard sku={item.node.skuNumber} data={item.node} />
                  </ProductGridItem>
                );
              } else {
                return true;
              }
            })}
          </ProductGrid>
          {allProducts.length > showLimit && (
            <ExploreMore onClick={() => setShowLimit(showLimit + 6)}>
              <button type="button" aria-label="button">
                <PrimaryButton text="Load More" />
              </button>
            </ExploreMore>
          )}
        </Container>
      </Section>
      <PhotoModal
        isVisible={isModalVisible}
        onClose={HandleModalClose}
        product={openProduct}
      />
    </Layout>
  );
};

export default GalleryPage;

export const pageQuery = graphql`
  query GalleryPageQuery {
    contentfulPageGallery {
      metaTitle
      metaDescription
      heroTitle
      gallerySectionDescription {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulProduct(sort: { order: ASC, fields: skuNumber }) {
      edges {
        node {
          price
          name
          url
          skuNumber
          image {
            gatsbyImageData(placeholder: BLURRED, quality: 90)
          }
        }
      }
    }
  }
`;
